import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { IoMenu } from "react-icons/io5";
import Logo from './Logo'

function NavBar({style,isHome}) {
  
  const isSmallSecreen = useMediaQuery({ query: '(max-width: 1000px)' })
  const isSmallS = useMediaQuery({ query: '(max-width: 700px)' })
  const [isShowMenu , setIsShowMenu ] = useState(false)
  const handleMenu  = () => {
    if(isShowMenu == false) {
      document.getElementById("SmallScreenMenu").style.transform = "translateX(0px)"
      setIsShowMenu(true)
    }else {
      document.getElementById("SmallScreenMenu").style.transform = "translateX(-700px)"
      setIsShowMenu(false)
    }
  }
  return (
    <nav style={style}>
        <div className='innerNav'>
          {isSmallS && <div className='SmallScreenMenu' id='SmallScreenMenu'>
            <div className='innerSmallScreenMenuHolder'>
              <Link to="/" onClick={handleMenu}>Home</Link>
              <Link to="/projects" onClick={handleMenu}>Projects</Link>
              {isHome && <> <a href="#about" onClick={handleMenu}>About</a> <a href="#services" onClick={handleMenu}>Services</a> </>}
              <Link to="/press" onClick={handleMenu}>Press</Link>
              {isHome && <a href="#contact" onClick={handleMenu}>Contact</a>}
            </div>
          </div>}
          <Logo/>
            <div className='menuHolder'>
              {!isSmallSecreen && (<>

              <Link to="/" 
              onMouseOver={()=>{
                document.getElementById("costumCurser").style.transform = "scale(2)"
                
              }}
              onMouseLeave={()=>{
                document.getElementById("costumCurser").style.transform = "scale(1)"
              }}>Home</Link>

              <Link to="/projects"
              onMouseOver={()=>{
                document.getElementById("costumCurser").style.transform = "scale(2)"
                
              }}
              onMouseLeave={()=>{
                document.getElementById("costumCurser").style.transform = "scale(1)"
              }}>Projects</Link>

              {isHome && <><a href="#about"
              onMouseOver={()=>{
                document.getElementById("costumCurser").style.transform = "scale(2)"
                
              }}
              onMouseLeave={()=>{
                document.getElementById("costumCurser").style.transform = "scale(1)"
              }}>About</a>

              <a href="#services"
              onMouseOver={()=>{
                document.getElementById("costumCurser").style.transform = "scale(2)"
                
              }}
              onMouseLeave={()=>{
                document.getElementById("costumCurser").style.transform = "scale(1)"
              }}>Services</a></>}

              <Link to="/press"
              onMouseOver={()=>{
                document.getElementById("costumCurser").style.transform = "scale(2)"
                
              }}
              onMouseLeave={()=>{
                document.getElementById("costumCurser").style.transform = "scale(1)"
              }}>Press</Link>

              {isHome && <a href="#contact"
              onMouseOver={()=>{
                document.getElementById("costumCurser").style.transform = "scale(2)"
                
              }}
              onMouseLeave={()=>{
                document.getElementById("costumCurser").style.transform = "scale(1)"
              }}>Contact</a>}
              </>)}
              {isSmallSecreen && (<IoMenu className='burgerMenu' onClick={()=>{
                handleMenu()
                
              }}/>)}
            </div>
        </div>
    </nav>
  )
}

export default NavBar