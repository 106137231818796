import React from 'react'

function Press({title,imagePath,link}) {
  return (
    <a href={link} className="pressCard" target='_blank'>
      <label>{title}</label>
      <img src={"/assets/"+imagePath} alt={title}></img>
        
    </a>
  )
}

export default Press