import React , {useState} from 'react'


function Project({data}) {
  const [showCase, setshowCase] = useState(false)

  const handleShow = () => {
    document.body.style.overflowY = "hidden"
    setshowCase(true)
  }
  const handleHide = () => {
    document.body.style.overflowY = "scroll"
    setshowCase(false)
  }
  return (
    <div className='Project'>
        {showCase && <div className="showCase" onClick={() => handleHide()}>
          <h1>Show Case : {data?.name}</h1>
          <h2>Place : {data?.place}</h2>
          <h2>Date : {data?.date}</h2>
          <h2>Area : {data?.area}</h2>
          <h2>Client : {data?.client}</h2>
          <p>
            <span style={{fontWeight : 900}}>Overview</span> : {data?.text}
          </p>
          <div className='innerShowCase'>
            {data?.images?.map(path=>(<img src={"/assets/"+path}></img>))}
          </div>
        </div>}
        <img  src={"/assets/"+data?.images[0]} alt={data?.name} onClick={()=>handleShow()}/>
        <label className="projectName">{data?.name}</label>
    </div>
  )
}

export default Project