import React, { useState , useEffect , useRef} from 'react';
import NavBar from '../components/NavBar';
import { useMediaQuery } from 'react-responsive'
import Project from '../components/Project';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

function Home() {
  const form = useRef();
  const [mousePosition, setMousePosition] = useState({ top: 0, left: 0 });
  const [projects , setProjects ] = useState({});
  

  useEffect(() => {
    const getDataFromJson = async () => {
      const res = await fetch("/data.json");
      const data = await res.json()
      setProjects(data?.projects)
      
    }
    getDataFromJson()
  },[])
  
  const handleMouseMove = (e) => {
    setMousePosition({ top: e.pageY, left: e.pageX });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_obow759', 'template_ihpp05s', form.current, {
        publicKey: 'Rj9g88N2nE6dJHjZB',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
    form.current.reset()
  }
  const isSmallSecreen = useMediaQuery({ query: '(max-width: 1000px)' })

  return (
    <div className="Home" onMouseMove={handleMouseMove}>
      
      <div className='costumCurser' id='costumCurser' style={{top: mousePosition.top - 10,left: mousePosition.left -10,backgroundColor : isSmallSecreen ? "" : "bisque"}}></div>

      <div className="Hero">
        <NavBar isHome={true} style={{paddingTop : "2em"}}/>
        <h1 className="HeroTitle" 
          onMouseOver={()=>{
            document.getElementById("costumCurser").style.transform = "scale(4)"
            
          }}
          onMouseLeave={()=>{
            document.getElementById("costumCurser").style.transform = "scale(1)"
          }}
        >We Convert Your Inner Peace To Your Leaving Space</h1>
        <div class="mouse"></div>
      </div>
      
      <div className="Projects">
          <h2>Projects</h2>
          <div className="ProjectsHolder">
            <Project data={projects[0]}/>
            <Project data={projects[1]}/>
          </div>
          <Link to="/projects" className='seeMore' onMouseOver={()=>{
            document.getElementById("costumCurser").style.transform = "scale(4)"
            document.getElementById("costumCurser").style.zIndex = -99
            
            
          }}
          onMouseLeave={()=>{
            document.getElementById("costumCurser").style.transform = "scale(1)"
            document.getElementById("costumCurser").style.zIndex = 1
            
          }}>More</Link>
      </div>

      <div className='About' id='about'>
          <div className='innerAbout'>
            <h1>About Us</h1>
            <p>chachia design is an interior architecture/design firm : which focuses on elegant and functional interiors, sustainable design and aesthetic considerations for the domestic environment </p>
            <button onMouseOver={()=>{
            document.getElementById("costumCurser").style.transform = "scale(.5)"
            
          }}
          onClick={()=>document.getElementById("contact")?.scrollIntoView()}
          onMouseLeave={()=>{
            document.getElementById("costumCurser").style.transform = "scale(1)"
            
            
          }}>Get In Touch</button>
          </div>
      </div>

      <div className="Services" id='services'>
        <div className='innerServices'>
            <h1>Services</h1>
            <div className="servicesHolder">
            <div className='service'>
              <img src={require('../images/3drendring.jpg')} alt="3d"></img>
              <div className="innerServiceHolder">
                <label>3D Rendering & Animation</label>
                <button>Order Now</button>
              </div>
            </div>

            <div className='service'>
              <img src={require('../images/archi.jpg')} alt="sketching"></img>
              <div className="innerServiceHolder">
                <label>Architecture & Interior Design</label>
                <button>Order Now</button>
              </div>
            </div>

            <div className='service'>
              <img src={require('../images/personalShopping.jpg')} alt="shopping"></img>
              <div className="innerServiceHolder">
                <label>Personal Shopping</label>
                <button>Order Now</button>
              </div>
            </div>

            <div className='service'>
              <img src="https://images.unsplash.com/photo-1495446815901-a7297e633e8d" alt="packs"></img>
              <div className="innerServiceHolder">
                <label>Courses & Packs</label>
                <button>Order Now</button>
              </div>
            </div>
            </div>
            
          </div>
      </div>

      <div className="contactHolder">
          <h1>Contact Us </h1>
          <div className='innerContactHolder' id='contact'>
            <iframe  onMouseEnter={()=>{document.getElementById("costumCurser").style.display = "none"}} onMouseLeave={()=>{document.getElementById("costumCurser").style.display = "block"}} src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3200.844514605049!2d10.590497999999998!3d36.65419099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzbCsDM5JzE1LjEiTiAxMMKwMzUnMjUuOCJF!5e0!3m2!1sen!2stn!4v1707823941073!5m2!1sen!2stn"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <form className="contactForm" ref={form} onSubmit={sendEmail}>
              <div>
                <label>Name :</label>
                <input type='text' placeholder="type your name..." name="name"></input>
              </div>
              <div>
                <label>Email :</label>
                <input type='email' placeholder="type your email..." name="email"></input>
              </div>
              <div>
                <label>Phone Number :</label>
                <input type='tel' placeholder="type your name..." name="phone"></input>
              </div>
              <div>
                <label>Ask Us How We Can Help ?</label>
                <textarea placeholder='type your message...' name='message'></textarea>
              </div>
              <input type="submit" value="submit"></input>
            </form>
          </div>
      </div>

      <footer>
        <span>Email - amina.chachia@gmail.com</span>
        <span>Copyright © 2012 - 2024 chachiaDesign®. All Rights Reserved.</span>
        <span>Phone - 29634101</span>
      </footer>
      {/* Add A Press Section  and Create Presses In Json File / Create Contact Section And Add Functionality Using The EmailJs / Add Footer With Phone Number And Email + Copy Write / Do The Projects page and Press Page*/}
    </div>
  );
}

export default Home;
