import React , {useEffect , useState} from 'react'
import NavBar from '../components/NavBar';
import { useMediaQuery } from 'react-responsive'
import Project from '../components/Project';

function Projects() {
    const [projects , setProjects ] = useState([]);
    const [mousePosition, setMousePosition] = useState({ top: 0, left: 0 });
    const isSmallSecreen = useMediaQuery({ query: '(max-width: 1000px)' });
  
    useEffect(() => {
      const getDataFromJson = async () => {
        const res = await fetch("/data.json");
        const data = await res.json()
        setProjects([...data?.projects])
        
      }
      getDataFromJson()
    },[])
  
    const handleMouseMove = (e) => {
      setMousePosition({ top: e.pageY, left: e.pageX });
    };
  
    return (
      <div onMouseMove={handleMouseMove} style={{minHeight:"100vh"}}>
  
        <div className='costumCurser' id='costumCurser' style={{top: mousePosition.top - 10,left: mousePosition.left -10,backgroundColor : isSmallSecreen ? "" : "bisque"}}></div>
        
        <NavBar isHome={false} style={{backgroundColor : "black",paddingBottom : "2em",position:"relative"}}/>
        
        <div className="Projects">
          <h2>Projects</h2>
          <div className="ProjectsHolder">
            
            {projects?.map(p => <Project data={p}/> )}
          </div>
      </div>
  
      </div>
    )
}

export default Projects