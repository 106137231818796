import React , {useEffect , useState} from 'react'
import NavBar from '../components/NavBar';
import { useMediaQuery } from 'react-responsive'
import Press from '../components/Press';
function PressPage() {
    const [press , setPress] = useState([]);
    const [mousePosition, setMousePosition] = useState({ top: 0, left: 0 });
    const isSmallSecreen = useMediaQuery({ query: '(max-width: 1000px)' });
  
    useEffect(() => {
      const getDataFromJson = async () => {
        console.log("working");
        const res = await fetch("/data.json");
        const data = await res.json()
        
        setPress(data?.press);
      }
     getDataFromJson()
    },[])
  
    const handleMouseMove = (e) => {
      setMousePosition({ top: e.pageY, left: e.pageX });
    };
  
    return (
      <div onMouseMove={handleMouseMove} style={{minHeight:"100vh"}}>
  
        <div className='costumCurser' id='costumCurser' style={{top: mousePosition.top - 10,left: mousePosition.left -10,backgroundColor : isSmallSecreen ? "" : "bisque"}}></div>
        
        <NavBar isHome={false} style={{backgroundColor : "black",paddingBottom : "2em",position:"relative"}}/>
        
        <div className="Press">
          <h1>Press</h1>
          <div className='innerPressHolder'>
            {press?.map(p=><Press title={p.title} link={p.link} imagePath={p.image}/>)}
          </div>
        </div>
  
      </div>
    )
}

export default PressPage