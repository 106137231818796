import React from 'react';
import ReactDOM from 'react-dom/client';

import "./style/main.css";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from './pages/Home';
import Projects from './pages/Projects'
import PressPage from './pages/PressPage';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path : "/projects",
    element : <Projects/>,
  },
  {
    path : "/press" ,
    element : <PressPage/>,
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

